import React from "react";
import { Link } from "gatsby";
import "../components/Layout/layout.css";

import SEO from "../components/SEO";
import Layout from "../containers/Layout";
import salesUrl from "../images/salesforce.png";
import logoUrl from "../images/golf4Change.png";
function PrivacyPage() {
  return(
      
    <div className="pages_container">
      <div className="logos_container">
        <div className="sales_logo">
            <img src={salesUrl as string} className="img_responsive"></img>
        </div>
        <div className="golf_logo">
            <img src={logoUrl as string} className="img_responsive"></img>
        </div>

      </div>
      <div className="pages_copy_container">
        <h1 className="h1_leader">Privacy Policy</h1>
        <p>We take our clients' privacy seriously. During the course your participation in the Golf For Change event, we accumulate limited personal information from you and other project participants including, First Name and Last Initial for display on our project scoreboard, and basic information on golf course/location.  This information will only be used for this project and will be deleted at the end of this project.  We restrict access to information about you (other than as displayed on the project scoreboard) to those employees who need to know that information.  We do not disclose any nonpublic information about project participants to any third party, except as may be required by law.  Any privacy or data requests can be emailed to: <a href="mailto:privacy@intersport.global">privacy@intersport.global</a>.    </p>
        <Link to="/">&#9664; Back to Home Page</Link>
      </div>
    </div>
  )

}

export default PrivacyPage;


